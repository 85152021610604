import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localePl from '@angular/common/locales/pl';
import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import { Injectable } from '@angular/core';
import { SupportedLanguages } from '@as/api';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocaleService {
  private localeSubject = new BehaviorSubject<SupportedLanguages>(SupportedLanguages.PL);
  locale$ = this.localeSubject.asObservable();

  constructor() {
    registerLocaleData(localePl, SupportedLanguages.PL);
    registerLocaleData(localeEn, SupportedLanguages.EN);
    registerLocaleData(localeIt, SupportedLanguages.IT);
    registerLocaleData(localeEs, SupportedLanguages.ES);
  }

  setLocale(locale: SupportedLanguages): void {
    this.localeSubject.next(locale);
  }

  getLocale(): string {
    return this.localeSubject.value;
  }
}
